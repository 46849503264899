import LazyLoad from "vanilla-lazyload";
require('./rwd-checker');
require('./form-animation');

$(function () {
    const lazyLoadInstance = new LazyLoad({
        elements_selector: ".lazy"
        // aby użyć do elementów dodajemy klasę .lazy
        // zamiast src w img podajemy data-src
        // zamiast background-image podajemy data-bg
    });

    require('./cookie-bar');
    require('./animations');
    require('./dropdown-menu');
    require('./mobile-menu');
    require('./sticky-header');
    require("./counter-animation");

    $('.gallery').each(function(){
        $(this).find('a').simpleLightbox();
    });

    if($('.simplelightbox').length > 0){
        $('.simplelightbox').simpleLightbox();
    }

    $('.project__slider').slick({
        arrows: true,
        speed: 500,
        fade: true,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 2000,
        prevArrow: '<button type="button" class="slick-prev strip strip--bottom strip--left"></button>',
        nextArrow: '<button type="button" class="slick-next strip strip--bottom strip--right"></button>',
    });

    $(function() {

        const eTop = $('body').offset().top;
        const secondsec = document.querySelector('.h-body');
        const y = secondsec.getBoundingClientRect().top + window.pageYOffset;
        let i = 0;

        $(window).scroll(function() {

            // if ((eTop - $(window).scrollTop()) > -140 && i != 1) {
            //     console.log(eTop - $(window).scrollTop());
            //     window.scrollTo({top: y, behavior: "smooth"});
            //     i = 1;
            // }

            // if ((eTop - $(window).scrollTop()) === 0) {
            //     i = 0;
            // }
            
            $('.header__logo, .header__hamburger').css({
                opacity: function() {
                var elementHeight = $(this).height(),
                    opacity = ((1 - (elementHeight + Math.abs( $(window).scrollTop() ) ) / elementHeight) * 0.2) + 1;
                    return opacity;
                }
            });

        });

    });

    
});